<script lang="ts" setup>import { computed as _computed, ref as _ref } from 'vue';

import type { mastodon } from 'masto'
import sparkline from '@fnando/sparkline'

defineProps({
  "history": null,
  "width": { default: 60 },
  "height": { default: 40 }
})

const historyNum = _computed(() => {
  if (!__props.history)
    return [1, 1, 1, 1, 1, 1, 1]
  return [...__props.history].reverse().map(item => Number(item.accounts) || 0)
})

const sparklineEl = _ref<SVGSVGElement>()
const sparklineFn = typeof sparkline !== 'function' ? (sparkline as any).default : sparkline

watch([(historyNum), (sparklineEl)], ([historyNum, sparklineEl]) => {
  if (!sparklineEl)
    return
  sparklineFn(sparklineEl, historyNum)
})
</script>

<template>
  <svg ref="sparklineEl" class="sparkline" :width="width" :height="height" stroke-width="3" />
</template>
