<script lang="ts" setup>import { computed as _computed } from 'vue';

import type { mastodon } from 'masto'

defineProps({
  "history": null,
  "maxDay": { default: 2 }
})

const ongoingHot = _computed(() => __props.history.slice(0, __props.maxDay))

const people = _computed(() =>
  ongoingHot.value.reduce((total: number, item) => total + (Number(item.accounts) || 0), 0),
)
</script>

<template>
  <p>
    {{ $t('command.n-people-in-the-past-n-days', [people, maxDay]) }}
  </p>
</template>
